import React from 'react';
import './about.css';
import img1 from '../../Assect/Mask group.webp';
import img2 from '../../Assect/Ellipse 2.webp';
import img3 from '../../Assect/skill.webp';
import Aboutimg1 from '../../Assect/About-img1.webp';
import Aboutimg2 from '../../Assect/About-img2.webp';
import Aboutimg3 from '../../Assect/About-img3.webp';
import SriramResumePDF from '../../Assect/Sriram Resume.pdf';

const About = () => {
    function handleDownload() {
        const link = document.createElement('a');
        link.href = SriramResumePDF;
        link.download = 'Sriram_Resume.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      
    return (
        <div className='About'>
            <div className="Acardtitle">
                <div className="Acard-container">
                    <div className="flex-container">
                        <div className="Acard-title">
                            <h3><span className="medium-font">Hi recruiters, hiring managers, designers,      and other friends!</span></h3>
                            <div className="card-subtitle">
                                <p>I'm Sriram, A UI/UX designer based in Chennai, currently working with Kanini Software Solutions. I create user-centered design solutions to delight and address real user needs.</p>
                                <p>I specialize in product and visual design, along with storytelling, research, problem-solving, visualization, and prototyping. My goal is to craft solutions that captivate users and effectively convey messages</p>
                            </div>
                        </div>
                        <div className="Acard">
                            <img className="image1" src={img1} alt="Image 1" />
                            <img className="image2" src={img2} alt="Image 2" />
                            <img className="image3" src={img3} alt="Image 3" />
                        </div>
                    </div>
                    <div className="button-container">
                    <a className="reach-out-button" href="mailto:hello@srirammuralidharan.com">Reach Out</a>

                        <button className="download-cv-button" onClick={handleDownload}>Download CV</button>
                    </div>
                    <br></br><br></br><br></br>
                    <hr className="line1" />
                    <div className="column-container1">
                        <div className="columnh">
                            <h4>My Approach on Design</h4>
                            
                        </div>
                        <div className="column">
                            <h4>Empathize</h4>
                            <p>Understand the problem by researching, observing and collecting information about the user's needs.</p>
                           
                            <h4>Prototype</h4>
                            <p>Design an early model of the product that solves the identified problem.</p>
                        </div>
                        <div className="column">
                            <h4>Define</h4>
                            <p>Analyze and synthesize the gathered information's to define and prioritize the core problem.</p>
                           
                            <h4>Test</h4>
                            <p>Test the product with users and iterate on it based on insights.</p>
                        </div>
                        <div className="column">
                            <h4>Ideate</h4>
                            <p>Brainstorm multiple ideas to generate potential solutions by thinking outside the box.</p>
                        </div>
                    </div>
                    <br></br>
                    <hr className="line" />
                    <div className="column-container">
    <div className="columnh">
       
        <h4>Why work with me</h4>
    </div>
    <div className="column">
        <img className="Aboutimage" src={Aboutimg1} alt="Image 1" />
        <h4>On time delivery</h4>
        <p>Focused on timely delivery without compromising on design</p>
    </div>
    <div className="column">
        <img className="Aboutimage"src={Aboutimg2} alt="Image 2" />
        <h4>Visually attractive</h4>
        <p>I consistently draw people in with my visuals, it's what sets me apart </p>
    </div>
    <div className="column">
        <img className="Aboutimage"src={Aboutimg3} alt="Image 3" />
        <h4>Client-centric</h4>
        <p>My priority is to ensure that the needs of the client are always met </p>
    </div>
</div>

                </div>
            </div>
        </div>
    );
}

export default About ;
