// CommonNav.js
import React, { useState, useEffect } from 'react';
import { Nav, Dropdown } from 'react-bootstrap';
import './Nav.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import SriramResumePDF from '../../Assect/Sriram Resume.pdf';

const CommonNav = () => {
    const [isMobileView, setIsMobileView] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 590);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleMenu = () => {
        setShowMenu(!showMenu);

           // Add or remove the class from the body to disable or enable scrolling
           if (!showMenu) {
            document.body.classList.add('mobile-menu-open');
        } else {
            document.body.classList.remove('mobile-menu-open');
        }
    };

    const handleNavItemClick = () => {
        // Close the mobile menu
        setShowMenu(false);
        
        // Enable scrolling by removing the class from the body
        document.body.classList.remove('mobile-menu-open');
    };

    const scrollToAbout = () => {
        const aboutSection = document.getElementById('about');
        if (aboutSection) {
            aboutSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div>
            <Nav className="horizontal-nav">
            <h1 className="title" as={Link} to="#" onClick={() => window.history.back()}>Sriram Muralidharan</h1>
                            {isMobileView && (
                    <Dropdown className="d-block d-sm-none" show={showMenu} style={{ top: showMenu ? 0 : '-100vh' }}>
                        <Dropdown.Toggle variant="secondary" id="dropdown-basic" onClick={toggleMenu}>
                            {showMenu ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faBars} />}
                        </Dropdown.Toggle>
                        {showMenu && (
                            <Dropdown.Menu className='mobnav'>
                                <hr className="aline" /><br></br>
                                <Dropdown.Item as={Link} onClick={handleNavItemClick} to="/">Home</Dropdown.Item>
                                <Dropdown.Item onClick={() => {scrollToAbout(); handleNavItemClick();}}>About</Dropdown.Item>
                                <Dropdown.Item as={Link} onClick={handleNavItemClick} to="/workpage">Work</Dropdown.Item>
                                <a href={SriramResumePDF} target="_blank" rel="noopener noreferrer">Resume</a>
                                <Dropdown.Item href="mailto:hello@srirammuralidharan.com">Contact</Dropdown.Item>
                                
                            </Dropdown.Menu>
                        )}
                    </Dropdown>
                )}
                {!isMobileView && (
                    <><Nav.Item className='nav-center-left'>
                    <Link to="/" className="nav-link">Home</Link>
                </Nav.Item>
                        <Nav.Item>
                            <Nav.Link onClick={scrollToAbout}>About</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link to="/workpage" className="nav-link">Work</Link>
                        </Nav.Item>
                        <Nav.Item className='nav-center-right'>
                        <a href={SriramResumePDF} target="_blank" rel="noopener noreferrer">Resume</a>
                        </Nav.Item>
                        <Nav.Item>
                        <Nav.Link href="mailto:hello@srirammuralidharan.com">Contact</Nav.Link>
                        </Nav.Item>
                    </>
                )}
            </Nav>
        </div>
    );
}

export default CommonNav;
