import "./Safedive.css";
import { useEffect,useState } from "react";
import CommonNav from '../Nav/CommonNav';
import watch from '../../Assect/watch.webp';
import scuba from '../../Assect/scuba.webp';
import demographic from '../../Assect/demographic.webp';
import groupimage from '../../Assect/group image.webp';
import group1 from '../../Assect/group1.webp';
import watchwithpages from '../../Assect/watchwithpages.webp';
import fullwatch from '../../Assect/fullwatch.webp';
import { Footertab } from "../Footer/Footertab";
import ScrollUpButton from '../Home/Scrollup'

const Safedive = () => {
    const scrollToAbout = () => {
        const aboutSection = document.getElementById('about');
        aboutSection.scrollIntoView({ behavior: 'smooth' });
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    const handleCloseModal = () => {
        setSelectedImage(null);
    };

    return (
        <div>
             <ScrollUpButton/>
            <CommonNav scrollToAbout={scrollToAbout} />
            <br></br><br></br><br></br><br></br><br></br>
            <div className="maindiv">
                <p className="mainheading">SAFE DIVE</p>
                <p className="mainpara">The Safe Dive App is designed with the intent to facilitate communication between scuba divers, navigate<br></br> them back to the boat, send emergency signals to the instructor, and manage oxygen levels and heart rate.</p>
                <br></br>
                <img className="watch" src={watch} alt="watch" onClick={() => handleImageClick(watch)}/>
                <br></br>
                <br></br>
                <br></br><br></br><br></br>
                <div className="contents">
                    <h4 className="capitaltext">INTRODUCTION</h4>
                    <h2 className="headingtext">What is the project about?</h2>
                    <p className="paratext">Scuba divers engage in deep-sea exploration for research purposes, and sometimes they lose proximity, making communication challenging.<br></br>
                        <br></br>
                        Continuous monitoring of their oxygen supply is essential, as well as checking their health conditions and notifying the dive team about the status of other divers. It's crucial to know their presence and be able to detect both external and internal emergencies, communicating back to the ship. There are situations, where audio signals fails most of the time.</p>
                </div>
                <br></br>
                <img className="scuba" src={scuba} alt="Scuba" onClick={() => handleImageClick(scuba)}/>
                <br></br>
                <br></br><br></br><br></br><br></br>
                <div className="contents">
                    <h2 className="headingtext">Technical Info</h2>
                    <div className="tablecontent">
                        <div className="insidetable">
                            <p className="thtext">The problem:</p>
                            <p className="paratext">During deep sea research, a scuba diver experienced a loss of communication with their team and couldn't pass signals in times of emergency, as well as being unable to monitor oxygen levels, water pressure, and heart rate.</p>
                        </div>
                        <div className="insidetable">
                            <p className="thtext">The goal:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </p>
                            <p className="paratext">To search the way for communication and signal. Also need to know the level of oxygen and water pressure</p>
                        </div>
                        <div className="insidetable">
                            <p className="thtext">The solution:</p>
                            <p className="paratext">Installation of beacons around the boat, that emit low frequency signals, Providing a diver to navigate by GPS. Also adding a SOS emergency alert to the divers application incase of emergency, they can sent a alert. Monitoring Oxygen level and health rate is a added feature which will help them to know the status of O2level and also health rate</p>
                        </div>
                    </div>
                </div>
                <br></br>
                <br></br><br></br>
                <div className="contents">
                    <h2 className="headingtext">Getting to know better my stakeholders and their needs</h2>
                    <p className="paratext">This is the problem statement provided by my mentor for which we need to propose a solution. Following a Zoom meeting with the X&Y research institute (company name or data cannot be disclosed), we asked open-ended questions such as: "What do you think are some current problems with the research?" and "What are the immediate and future needs of this application?"
                        <br></br>
                        <br></br>
                        Subsequently, we conducted three one-on-one interviews with volunteers. During these interviews, volunteers shared their experiences diving underwater, the challenges they face, and how they address them.</p>
                  
                    <div className="linepart">
                        <div className="vertical-linesafe"></div>
                        <p className="linepara">Their needs are as follows: They require a device that provides vital information about their health, their co-divers' presence, and the ability to send emergency signals and navigate to the ship in case of any threats. Overall, a perfect application that facilitates communication and navigation.</p>
                    </div>
                </div>
                <br></br><br></br><br></br>
                <div className="contents">
                    <h4 className="capitaltext">DEFINE</h4>
                    <h2 className="headingtext">What exactly is the problem and who’s affected by it?</h2>
                    <p className="paratext">The major problem they faced during one of their diving sessions was when they encountered low oxygen levels, rendering them unable to send signals to their team members. Many people have encountered this situation, and tragically, many have died as a result. Approximately 100 people die in North America every year due to similar incidents.</p>
                    <br></br>
                    <p className="paratext">Reason behind high death rates: <span className="spantext">Insufficient gas(50%), Entrapment(30%), Equipment problem(20%)</span></p>
                </div>
                <br></br>
                <br></br><br></br>
                <div className="contents">
                    <h2 className="headingtext">so...who are we designing for?</h2>
                    <p className="paratext">With the information in hand and combining user research learning, we created four personas to help us align our design better to meet their persona’s goals. These personas are Researcher, Divers, instructor and Navigator. We later used these personas when designing to ensure that our solution looped back to the needs and goals outlined in them.</p> 
                </div>
               <br></br>
                <img className="demographic" src={demographic} alt="demographic" onClick={() => handleImageClick(demographic)}/>
                <br></br><br></br><br></br><br></br><br></br><br></br>
                <div className="contents">
                    <h2 className="headingtext">Understanding what’s the general journey of user</h2>
                    <p className="paratext">To get a better understanding of diver’s experience in underwater we have connected with many divers and understand their problems and needs </p>
                </div>
                <br></br>
                <img className="groupimage" src={groupimage} alt="groupimage" onClick={() => handleImageClick(groupimage)}/>
                <br></br><br></br><br></br><br></br><br></br><br></br>
                <div className="contents">
                    <h4 className="capitaltext">IDEATION</h4>
                    <h2 className="headingtext">Brainstormed the possible UX for the new feature</h2>
                    <p className="paratext">Designing a new feature for scuba dive research experience it is  required to brainstorm not only the design but how we would introduce this new flow to users. We explored different  challenges and ideated on different solutions and sketched out the user flows. Then we discussed and decided the most feasible solution for this new feature.</p>
                    <br></br>
                    <img className="group1" src={group1} alt="group1" onClick={() => handleImageClick(group1)}/>
                </div>
                <br></br><br></br><br></br><br></br>
                <div className="contents">
                    <h4 className="capitaltext">DESIGN</h4>
                    <h2 className="headingtext">Final Design</h2>
                    <p className="paratext"><span className="spantext">Our simple solution:</span> Installation of beacons around the boat, transmits the signal, Providing a diver to navigate by GPS. Also adding a SOS emergency alert to the divers application incase of emergency, they can sent a alert. Monitoring Oxygen level and health rate is a added feature which will help them to know the status of O2level and also health rate</p>
                    
                    <div className="linepart">
                        <div className="vertical-line2safe"></div>
                        <p className="linepara">These beacons can capture the distance and exact location of the divers and can be shared among the divers.</p>
                    </div>
                    <br></br><br></br><br></br>
                    <h3 className="subheading">Home Screen</h3>
                    <p className="paratext">Dashboard screen where all of the monitor data's are present, which includes water temperature, time left, oxygen level, heat rate etc.,</p>
                  
                    <img className="fullwatch" src={fullwatch} alt="fullwatch" onClick={() => handleImageClick(fullwatch)}/>
                    <br></br><br></br>
                    <h3 className="subheading">Other Screens</h3>
                   
                    <img className="fullwatch" src={watchwithpages} alt="watchwithpages" onClick={() => handleImageClick(watchwithpages)}/>
                    <br></br><br></br><br></br>
                    <p className="paratext">So let’s break this down one by one.</p>
                    <ul className="paratext">
                        <li>The first screen displays oxygen levels, showing both average and maximum data. Exceeding the maximum poses a danger, triggering an automatic signal to alert other divers for rescue. Similarly, on the second screen, the heart rate should not surpass the maximum threshold. If it does, an automatic signal is relayed to other divers and the boat for immediate attention.</li>
                        <li>The third screen serves as a navigation interface, displaying the locations of other divers and the boat. This allows divers to effortlessly locate their teammates, facilitating easy navigation and coordination underwater.</li>
                        <li>The last screen functions as an emergency SOS interface. In times of danger, one can simply click on it to transmit a distress signal to both other divers and the boat, ensuring swift assistance and support.</li>
                    </ul>
                </div>
                <br></br><br></br><br></br>
                <div className="contents">
                    <h2 className="headingtext">Takeaways</h2>
                    <p className="subsubheading">So these were my learnings after working on this project.</p>
                    <ul className="paratext">
                        <li>It was my first time working on a watch app, so it was a very interesting thing for me to design.</li>
                        <li>After encountering numerous complex challenges that users faced, I prioritized ensuring that the primary flow of the application was user-friendly and fast. Given the underwater environment in which the application is used, simplicity and speed were essential. Through multiple iterations and incorporating user feedback, I successfully streamlined the user experience to meet these requirements.</li>
                        <li>I just had 1day to ship this. So I learned how to deliver designs with a tight deadline</li>
                    </ul>
                </div>
                <br></br><br></br><br></br>
                <div className="contents">
                    <h2 className="headingtext">And… That’s a wrap!</h2>
                    <p className="paratext">I hope you guys found this case study useful and informative. Feel free to hit me up on any social media platform if you have any questions. I would be more than happy to have a chat with you.</p>
                </div>
                <br></br>
                <br></br><br></br><br></br>
            </div>
            {selectedImage && (
                <div className="modal">
                    <span className="close" onClick={handleCloseModal}>&times;</span>
                    <img src={selectedImage} alt="Selected Image" className="modal-image" />
                </div>
            )}
            <Footertab />
        </div>
    );
};

export default Safedive;