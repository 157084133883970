import React from 'react';
import myImage from '../../Assect/designsystem.webp'; // Import the image
import './designsystem.css'; // Import the CSS file

const Designsystem = () => {
    return (
        <div id="DesignSystem">
        <div className="pd-font">
                <p>Design System</p>
                <hr className="dline" />
            </div>
            <a href="https://www.figma.com/file/xfal5pbuvfv6dC9CTLvbWu/Design-system?type=design&node-id=0%3A1&mode=design&t=bVYGTNutcpcLnSL2-1" target="_blank" rel="noopener noreferrer">
    <div className="Designsystem-container">
        <div className="Designsystem-content">
            <h3>Synergy</h3>
            <p>We have designed and developed many design systems for healthcare, finance, and other domains. We build robust and cohesive design systems for easy scalability.</p>
        </div>
        <img src={myImage} alt="Description of the image" />
    </div>
</a>
</div>
    );
}

export {Designsystem};
