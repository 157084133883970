import React from 'react';
import { Link } from 'react-router-dom'; 
import './visuals.css';
import img1 from '../../Assect/s1.webp';
import img2 from '../../Assect/s2.webp';
import img3 from '../../Assect/s3.webp';
import img4 from '../../Assect/s4.webp';
import img5 from '../../Assect/s5.webp';
import img6 from '../../Assect/s6.webp';
import img7 from '../../Assect/s7.webp';
import img8 from '../../Assect/s8.webp';
import img9 from '../../Assect/s9.webp';
import img10 from '../../Assect/s10.webp';
import img11 from '../../Assect/s11.webp';
import img12 from '../../Assect/s12.webp';
import img13 from '../../Assect/s13.webp';
import img14 from '../../Assect/s14.webp';
import img15 from '../../Assect/s15.webp';
import img16 from '../../Assect/s16.webp';

const Visuals = () => {
    return (
        <div className='visuals'>
            <div className="vcardtitle">

                <div className="heading-container">
                    <h3>
                        <span className="medium-font">Let’s View Some Cool </span>
                        <span className="bold-font">Visuals First</span>
                    </h3><div className="vabutton" >
                    <Link to="/workpage"  className="vdownload-cv-button"  style={{ marginLeft: '40px', borderColor: 'black' }}>View More Visuals</Link>
                   
                </div></div>
                <div className="vcard-container">
                    <div className="vcard-title">
                        <div className="cardv-subtitle">
                            <p>To hone my quick-fire prototyping & design skills, I designed and animated an app/website everyday. </p>
                        </div>
                    </div>
                </div>
            </div>
            <br></br>
            <div className="image-row">
                <img className='scrollimage' src={img1} alt="Image 1" />
                <img  className='scrollimage'src={img2} alt="Image 2" />
                <img className='scrollimage' src={img3} alt="Image 3" />
                <img className='scrollimage' src={img4} alt="Image 4" />
                <img className='scrollimage' src={img5} alt="Image 5" />
                <img className='scrollimage' src={img6} alt="Image 6" />

                <img className='scrollimage' src={img1} alt="Image 1" />
                <img  className='scrollimage'src={img2} alt="Image 2" />
                <img className='scrollimage' src={img3} alt="Image 3" />
                <img className='scrollimage' src={img4} alt="Image 4" />
                <img className='scrollimage' src={img5} alt="Image 5" />
                <img className='scrollimage' src={img6} alt="Image 6" />


                <img className='scrollimage' src={img1} alt="Image 1" />
                <img  className='scrollimage'src={img2} alt="Image 2" />
                <img className='scrollimage' src={img3} alt="Image 3" />
                <img className='scrollimage' src={img4} alt="Image 4" />
                <img className='scrollimage' src={img5} alt="Image 5" />
                <img className='scrollimage' src={img6} alt="Image 6" />


                <img className='scrollimage' src={img1} alt="Image 1" />
                <img  className='scrollimage'src={img2} alt="Image 2" />
                <img className='scrollimage' src={img3} alt="Image 3" />
                <img className='scrollimage' src={img4} alt="Image 4" />
                <img className='scrollimage' src={img5} alt="Image 5" />
                <img className='scrollimage' src={img6} alt="Image 6" />
            </div>
            <br></br>
            <div className="image-row2">
                <img className='scrollimages' src={img7} alt="Image 1" />
                <img className='scrollimages' src={img8} alt="Image 2" />
                <img className='scrollimages' src={img9} alt="Image 3" />
                <img className='scrollimages' src={img10} alt="Image 4" />
                <img className='scrollimages' src={img11} alt="Image 5" />
                <img className='scrollimages' src={img12} alt="Image 6" />

                <img className='scrollimages' src={img7} alt="Image 1" />
                <img className='scrollimages' src={img8} alt="Image 2" />
                <img className='scrollimages' src={img9} alt="Image 3" />
                <img className='scrollimages' src={img10} alt="Image 4" />
                <img className='scrollimages' src={img11} alt="Image 5" />
                <img className='scrollimages' src={img12} alt="Image 6" />


                <img className='scrollimages' src={img7} alt="Image 1" />
                <img className='scrollimages' src={img8} alt="Image 2" />
                <img className='scrollimages' src={img9} alt="Image 3" />
                <img className='scrollimages' src={img10} alt="Image 4" />
                <img className='scrollimages' src={img11} alt="Image 5" />
                <img className='scrollimages' src={img12} alt="Image 6" />


                <img className='scrollimages' src={img7} alt="Image 1" />
                <img className='scrollimages' src={img8} alt="Image 2" />
                <img className='scrollimages' src={img9} alt="Image 3" />
                <img className='scrollimages' src={img10} alt="Image 4" />
                <img className='scrollimages' src={img11} alt="Image 5" />
                <img className='scrollimages' src={img12} alt="Image 6" />
            </div>
            <br></br>
            <div className="image-row3">
                <img className='scrollimage' src={img13} alt="Image 1" />
                <img className='scrollimage' src={img14} alt="Image 2" />
                <img className='scrollimage' src={img15} alt="Image 3" />
                <img className='scrollimage' src={img16} alt="Image 4" />
                <img className='scrollimage' src={img7} alt="Image 5" />
                <img className='scrollimage' src={img3} alt="Image 6" />


                <img className='scrollimage' src={img13} alt="Image 1" />
                <img className='scrollimage' src={img14} alt="Image 2" />
                <img className='scrollimage' src={img15} alt="Image 3" />
                <img className='scrollimage' src={img16} alt="Image 4" />
                <img className='scrollimage' src={img7} alt="Image 5" />
                <img className='scrollimage' src={img3} alt="Image 6" />


                <img className='scrollimage' src={img13} alt="Image 1" />
                <img className='scrollimage' src={img14} alt="Image 2" />
                <img className='scrollimage' src={img15} alt="Image 3" />
                <img className='scrollimage' src={img16} alt="Image 4" />
                <img className='scrollimage' src={img7} alt="Image 5" />
                <img className='scrollimage' src={img3} alt="Image 6" />


                <img className='scrollimage' src={img13} alt="Image 1" />
                <img className='scrollimage' src={img14} alt="Image 2" />
                <img className='scrollimage' src={img15} alt="Image 3" />
                <img className='scrollimage' src={img16} alt="Image 4" />
                <img className='scrollimage' src={img7} alt="Image 5" />
                <img className='scrollimage' src={img3} alt="Image 6" />
            </div>
            <div className="vubutton" >
            <Link to="/workpage" className="vdownload-cv-button">View More Visuals</Link>
            </div>
        </div>
    );
}

export { Visuals };
