// Homepage.jsx
import React from 'react';
import CommonNav from '../Nav/CommonNav';
import './homepage.css';
import '../Nav/Nav.css';
import { Visuals } from './Visuals';
import { Slideshow } from './Slideshow'; // Import the Slideshow component
import videoSource from '../../../src/Assect/head img.webp';
import videoSource1 from '../../../src/Assect/head img1.webp';
import videoSource2 from '../../../src/Assect/head img2.webp';
import videoSource3 from '../../../src/Assect/head img3.webp';
import videoSource4 from '../../../src/Assect/head img4.webp';
import videoSource5 from '../../../src/Assect/head img5.webp';
import videoSource6 from '../../../src/Assect/head img6.webp';
import videoSource7 from '../../../src/Assect/head img7.webp';
import videoSource8 from '../../../src/Assect/head img8.webp';
import imgs1 from '../../../src/Assect/C_Logos.webp';
import img1 from '../../../src/Assect/cardimage.webp';
import About from './About'; // Import the About component
import { Bestproject } from './Bestpoject';
import { ScrollableProjects } from './Scrollproject';
import { Footertab } from '../Footer/Footertab';
import ScrollUpButton from './Scrollup'

const Homepage = () => {
  const scrollToAbout = () => {
    const aboutSection = document.getElementById('about'); // Get the About section by id
    aboutSection.scrollIntoView({ behavior: 'smooth' }); // Scroll to the About section smoothly
  };
 
  const images = [videoSource, videoSource1, videoSource2, videoSource3, videoSource4, videoSource5, videoSource6, videoSource7, videoSource8, ];
  return (
    <>
     
      <CommonNav scrollToAbout={scrollToAbout} /> {/* Pass scrollToAbout function as a prop */}
      {/* Head */}
      <div id="home-page">
        <div className="content-wrapper">
          <div className="content">
            <p className="heading">I design applications that</p>
            <h1 className="Sub-heading">showcase the future!</h1>
          </div>
          {/* video */}
          <div className="video-subtitle">
            <div className="video-container">
            <Slideshow images={images} />
            {/* <img className='fullscreen-video' src={videoSource} alt='' />
            <img className='fullscreen-video' src={videoSource1} alt='' />
            <img className='fullscreen-video' src={videoSource2} alt='' /> */}
              {/* <video className="fullscreen-video" autoPlay muted loop>
                <source src={videoSource} type="video/webm" />
                Your browser does not support the video tag.
              </video> */}
            </div>
          </div>
        </div>
        {/* LogoImages */}
        <div className="subtitle">
          <p>I’ve worked for the Best</p>
          <img className='card-image' src={imgs1} alt='' /> {/* Remove redundant alt attribute */}
        </div>
</div>

        {/* Card */}
        <div className="cardtitle">
          <div className="card-container">
            <div className="card-title">
              <h3><span className="medium-font">Crafting Experiences for</span> <span className="bold-font">Any Device</span></h3>
              <div className="card-subtitlec">
                <p>Simple. Minimalistic. Convenient. keeping it simple and user-friendly across different screens </p>
              </div>
            </div>
            <div className="cardh">
              <img src={img1} alt="Image 1" />
            </div>
          </div>
        </div>
      
      <Visuals />
      <div id="about"> {/* Add id attribute to the About section */}
        <About />
      </div>
      <Bestproject />
      <ScrollableProjects />
      <Footertab />
      <ScrollUpButton/>
    </>
  );
};

export default Homepage;
