import './meetmuseai.css';
import meetmuse from '../../Assect/meetmuse.webp';
import heymeetmuse from '../../Assect/heymeetmuse.webp';
import meetsticky from '../../Assect/meetsticky.webp';
import demographicmeet from '../../Assect/demographicmeet.webp';
import groupimage1 from '../../Assect/groupimage1.webp';
import group2 from '../../Assect/group2.webp';
import meethome from '../../Assect/meethome.webp';
import step1 from '../../Assect/step1.webp';
import step2 from '../../Assect/step2.webp';
import step22 from '../../Assect/step22.webp';
import step3 from '../../Assect/step3.webp';
import { Footertab } from '../Footer/Footertab';
import { useEffect,useState } from 'react';
import CommonNav from '../Nav/CommonNav';
import ScrollUpButton from '../Home/Scrollup'
const Meetmuse = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const scrollToAbout = () => {
        const aboutSection = document.getElementById('about');
        aboutSection.scrollIntoView({ behavior: 'smooth' });
    };
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    const handleCloseModal = () => {
        setSelectedImage(null);
    };
    return (
        <div>
            <ScrollUpButton/>
             <CommonNav scrollToAbout={scrollToAbout} />
             <br></br><br></br><br></br><br></br><br></br>
            <div className="maindiv">
                <p className="mainheading">MEETMUSE AI</p>
                <p className="mainpara">Effortlessly transform meeting transcripts into concise summaries with our meeting summary app. Simply <br></br>upload the transcript and receive key insights in seconds</p>
                <br></br>
                <img className="meetmuse" src={meetmuse} alt="meetmuse" onClick={() => handleImageClick(meetmuse)}/>
                <br></br><br></br><br></br><br></br><br></br>
                <div className="contents">
                    <h4 className="capitaltext">INTRODUCTION</h4>
                    <h2 className="headingtext">What is the project about?</h2>
                    <p className="paratext">The project is an innovative meeting summary app that streamlines the process of condensing meeting transcripts into concise summaries. Users can effortlessly upload transcripts, allowing the app to generate key insights swiftly, enhancing productivity and efficiency in post-meeting analysis.</p>
                </div>
                <br></br><br></br>
                <img className="heymeetmuse" src={heymeetmuse} alt="heymeetmuse" onClick={() => handleImageClick(heymeetmuse)}/>
                <br></br>
                <br></br><br></br><br></br><br></br>
                <div className="contents">
                    <h2 className="headingtext">Technical Info</h2>
                    <div className="tablecontent">
                        <div className="insidetable">
                            <p className="thtext">The problem:</p>
                            <p className="paratext">Usually in meetings, people listen to call recordings, view transcripts, make notes, and prepare minutes of meetings (MOM). This process consumes a lot of time.</p>
                        </div>
                        <div className="insidetable">
                            <p className="thtext">The goal:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </p>
                            <p className="paratext">To have a automatic reports of the meeting.</p>
                        </div>
                        <div className="insidetable">
                            <p className="thtext">The solution:</p>
                            <p className="paratext">To create an AI tool that assists in analyzing transcripts and provides call summaries.</p>
                        </div>
                    </div>
                </div>
                <br></br><br></br><br></br><br></br>
                <div className="contents">
                    <h2 className="headingtext">Getting to know better my stakeholders and their needs</h2>
                    <p className="paratext">This project within my organization brought me immense joy when the client proposed the idea. I made a personal commitment to myself to deliver a better solution for this product, focusing on simplicity and ease of use.
                    </p>
                    <div className="linepart">
                        <div className="vertical-linemeet"></div>
                        <p className="linepara">Their needs are as follows: They require an AI tool to analyze transcripts and provide summaries, aiming for a simple, minimalistic interface. The main objective is to ensure ease of use, even for non-techy individuals.</p>
                    </div>
                </div>
                <br></br><br></br>
                <img className="meetsticky" src={meetsticky} alt="meetsticky" onClick={() => handleImageClick(meetsticky)}/>
                <br></br><br></br><br></br><br></br><br></br><br></br>
                <div className="contents">
                    <h4 className="capitaltext">DEFINE</h4>
                    <h2 className="headingtext">What exactly is the problem and who’s affected by it?</h2>
                    <p className="paratext">The major problem people face is listening to recordings, where they might miss crucial details, leading to bigger issues. Additionally, there's the challenge of locating specific questions and answers within the meeting, often requiring multiple listens to pinpoint the exact time.</p>
                    <br></br>
                    <p className="paratext">Major Problems: <span className="spantext">Time consuming, Missing details, Wrong summary</span></p>
                    <br></br><br></br><br></br>
                    <h2 className="headingtext">so...who are we designing for?</h2><br></br>
                    <p className="paratext">With the information in hand and combining user research learning, we created four personas to help us align our design better to meet their persona’s goals. These personas are BSA, QA and Researchers. We later used these personas when designing to ensure that our solution looped back to the needs and goals outlined in them.</p>
                </div>
                
                <img className="demographic" src={demographicmeet} alt="demographicmeet" onClick={() => handleImageClick(demographicmeet)}/>
                <br></br><br></br><br></br><br></br><br></br>
                <div className="contents">
                    <h2 className="headingtext">Understanding what’s the general journey of user</h2>
                    <p className="paratext">To get a better understanding,  we have connected with many It professionals</p>
                </div>
               
                <img className="groupimage" src={groupimage1} alt="groupimage" onClick={() => handleImageClick(groupimage1)}/>
                <br></br><br></br><br></br><br></br>
                <div className="contents">
                    <h4 className="capitaltext">IDEATION</h4>
                    <h2 className="headingtext">Brainstormed the possible UX for the new feature</h2>
                    <p className="paratext">We're designing a new feature to create call summaries with ChatGPT AI. We're brainstorming design and how to introduce it to users. We've explored challenges, ideated solutions, sketched user flows, and agreed on the best approach.</p>
                    <br></br><br></br>
                    <img className="group1" src={group2} alt="group1" onClick={() => handleImageClick(group2)}/>
                    <br></br><br></br><br></br><br></br>
                </div>
                <div className="contents">
                    <h4 className="capitaltext">DESIGN</h4>
                    <h2 className="headingtext">Final Design</h2>
                    <p className="paratext"><span className="spantext">Our simple solution:</span>  With the help of the ChatGPT API, after the user uploads the transcript, we will pass it to the AI for analysis. The AI will then provide a detailed summary of the meeting. Additionally, users will have the option to ask questions, and the AI will process those questions and provide answers along with the summary.</p>
                    <br></br><br></br>
                    <h3 className="subheading">Home Screen</h3>
                    <p className="paratext">The primary focus of the Home screen is to guide users on how to use the application to download the meeting summary.</p>
                    <p className='paratext'>Introducing a visual representation of the process, featuring the MeetMuse character alongside a personalized message, enhances the connection between users and the application.</p>
                    <p className='paratext'>To make the process very simple and easy to use, I have mad it the process into 3 steps like a stepper form</p>
                </div>
                
                <img className="meethome" src={meethome} alt="meethome" onClick={() => handleImageClick(meethome)}/>
                <br></br><br></br><br></br>
                <div className="contents">
                    <h2 className="subheading">Step 1.</h2>
                    <p className="paratext">The first step of the process is to <span className="spantext">Upload Transcript</span></p>

                    <p className="paratext">In this screen, on the left side, I have displayed the progress stepper, enabling users to comprehend the upcoming steps. In the center portion, I have positioned the primary elements for file uploading, accompanied by illustrations for an enhanced visual experience, as well as descriptions to facilitate better understanding.</p>
                </div>
               
                <img className="meethome" src={step1} alt="step1" onClick={() => handleImageClick(step1)}/>
                <br></br><br></br><br></br><br></br>
                <div className="contents">
                    <h2 className="subheading">Step 2.</h2>
                    <p className="paratext">The Second step of the process is to <span className="spantext">Ask Questions</span></p>

                    <p className="paratext">Within the application, this screen plays a crucial role, enabling users to pose questions related to significant topics discussed during the call. These questions typically aim to capture exact statements made by participants for documentation purposes. The AI support then analyzes the transcript to furnish answers in the final summary section.</p>
                </div>
             
                <img className="meethome" src={step2} alt="step2" onClick={() => handleImageClick(step2)}/>
                <br></br><br></br><br></br>
                <div className='contents'>
                    <p className='paratext'>Users have the freedom to ask any number of questions they desire, and each question includes a delete option, granting users the ability to remove questions individually.</p>
                </div>
              
                <img className="meethome" src={step22} alt="step2-2" onClick={() => handleImageClick(step22)}/>
                <br></br><br></br><br></br><br></br>
                <div className="contents">
                    <h2 className="subheading">Step 3.</h2>
                    <p className="paratext">The final step of the process is to <span className="spantext">Summary</span></p>

                    <p className="paratext">The screen features both the summary and answers, along with the option to download each. I've organized them into tabs for easy navigation and effortless viewing of the data.</p>
                </div>
               
                <img className="step3" src={step3} alt="step3" onClick={() => handleImageClick(step3)}/>
                <br></br><br></br>
                <br></br><br></br><br></br><br></br>
                <div className="contents">
                    <h2 className="headingtext">Takeaways</h2>
                    <p className="paratext">So these were my learnings after working on this project.</p>
                    <ul className="paratext">
                        <li>I felt very happy working on my first AI application.</li>
                        <li>Getting the information architecture right for the new application proved to be more challenging than expected. However, after several iterations and feedback sessions, I managed to pull it off satisfactorily.</li>
                    </ul>
                    <br></br><br></br>
                    <h2 className="headingtext">And… That’s a wrap!</h2>
                    <p className='paratext'>I hope you guys found this case study useful and informative. Feel free to hit me up on any social media platform if you have any questions. I would be more than happy to have a chat with you.</p>
                </div>
            </div>
            {selectedImage && (
                <div className="modal">
                    <span className="close" onClick={handleCloseModal}>&times;</span>
                    <img src={selectedImage} alt="Selected Image" className="modal-image" />
                </div>
            )}
            <br></br>
            <br></br><br></br><br></br>
            <Footertab />
        </div>
    );
};
export default Meetmuse;