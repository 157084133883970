// App.js
import './App.css';
import Homepage from './Component/Home/Homepage';
import Workpage from './Component/Work/Workpage';
import About from './Component/Home/About';
import { Routes, Route } from 'react-router-dom'; 
import Safedive from './Component/Casestudy/Safedive';
import Meetmuse from './Component/Casestudy/meetmuseai';
import Credentialling from './Component/Casestudy/Credentialling';
import Rento from './Component/Casestudy/Rento';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/workpage" element={<Workpage />} />
        <Route path="/About" element={<About />} />
        <Route path="/safedive" element={<Safedive/>}/>
        <Route path="/meetmuse" element={<Meetmuse/>}/>
        <Route path="/credentialling" element={<Credentialling/>}/>
        <Route path="/rento" element={<Rento/>}/>
      </Routes>
    </div>
  );
}

export default App;
