import React, { useRef, useEffect } from 'react';
import './scrollproject.css';
import scrollimg1 from '../../../src/Assect/scrollimg1.webp';
import scrollimg2 from '../../../src/Assect/scrollimg2.webp';
import scrollimg3 from '../../../src/Assect/scrollimg3.webp';
import scrollimg4 from '../../../src/Assect/scrollimg4.webp';
import scrollimg5 from '../../../src/Assect/scrollimg5.webp';
import scrollimg6 from '../../../src/Assect/scrollimg6.webp';
import scrollimg7 from '../../../src/Assect/scrollimg7.webp';
import scrollimg9 from '../../../src/Assect/scrollimg9.webp';

const ScrollableProjects = () => {
    const containerRef = useRef(null);

    useEffect(() => {
        const container = containerRef.current;

        // Calculate total width of all images
        const totalWidth = container.scrollWidth;

        // Calculate duration based on total width
        const duration = totalWidth / 200; // Adjust speed by changing the divisor

        // Set animation duration dynamically
        container.style.animationDuration = `${duration}s`;
    }, []);

    return (
        <div className="marquee">
              <div>
                    <div className="pd-font"> <p>A breakdown of what i do <hr className="pline" />  </p> </div><br></br>

                    </div>
            <div className="marquee-content" ref={containerRef}>
                {[...Array(16)].map((_, index) => (
                    <div className="marquee-item" key={index}>
                        <img className='scrollimage' src={getImageByIndex(index)} alt={`Card ${index + 1}`} />
                        <p className='scrollhead'>{getLabelByIndex(index)}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

function getImageByIndex(index) {
    switch (index % 8) {
        case 0:
            return scrollimg1;
        case 1:
            return scrollimg2;
        case 2:
            return scrollimg3;
        case 3:
            return scrollimg4;
        case 4:
            return scrollimg5;
        case 5:
            return scrollimg6;
        case 6:
            return scrollimg7;
        case 7:
            return scrollimg9;
        default:
            return '';
    }
}

function getLabelByIndex(index) {
    switch (index % 8) {
        case 0:
            return 'Products';
        case 1:
            return 'Mobile';
        case 2:
            return 'Websites';
        case 3:
            return 'Web Apps';
        case 4:
            return 'Animation';
        case 5:
            return 'UI Cards';
        case 6:
            return 'Design System';
        case 7:
            return 'Presentation';
        default:
            return '';
    }
}

export { ScrollableProjects };
