// FooterTab.jsx

import React from 'react';
import './Footertab.css';
import iconimg1 from '../../../src/Assect/uil_linkedin.webp';
import iconimg2 from '../../../src/Assect/icon-park-solid_dribble.webp';
import iconimg3 from '../../../src/Assect/ri_behance-fill.webp';


const Footertab = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-section about">

                    <h3><span class="medium-footer">Let’s work together</span><br></br>
                        <span class="medium-footers">Get in touch..</span></h3>
                </div>
                <hr className="fline" />


                <div className="footer-bottom">
                    <div className="copyright-social">
                        &copy; 2023 All Rights Reserved. Made with ❤️
                        <div className="socials">
                            <a href="https://www.linkedin.com/in/sriram-uiux/"><img src={iconimg1} alt="Image 1" /></a>
                            <a href="https://dribbble.com/sriram-uiux"><img src={iconimg2} alt="Image 2" /></a>
                            <a href="https://www.behance.net/sriramuiux"><img src={iconimg3} alt="Image 3" /></a>
                        </div>
                    </div>
                </div>

             </div>
        </footer>
    );
}

export { Footertab };
