// Slideshow.jsx
import React, { useState, useEffect } from 'react';
import './Slideshow.css'; // Import CSS for slideshow styles

const Slideshow = ({ images }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      // Increment the index to show the next image
      setIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2000); // Change image every 3 seconds

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, [images.length]); // Re-run effect when images array changes

  return (
    <div className="slideshow">
      {images.map((image, i) => (
        <img
          key={i}
          className={`fullscreen-video ${i === index ? 'active' : ''}`}
          src={image}
          alt={`Slideshow Image ${i + 1}`}
        />
      ))}
    </div>
  );
};

export { Slideshow }; // Export the component as a named export
