import rento from '../../Assect/rento.webp';
import itimage from '../../Assect/itimage.webp';
import mobile1 from '../../Assect/mobile1.webp';
import mobile2 from '../../Assect/mobile2.webp';
import mobile3 from '../../Assect/mobile3.webp';
import mobile4 from '../../Assect/mobile4.webp';
import mobile5 from '../../Assect/mobile5.webp';
import mobile6 from '../../Assect/mobile6.webp';
import mobile7 from '../../Assect/mobile7.webp';
import mobile8 from '../../Assect/mobile8.webp';
import mobile9 from '../../Assect/mobile9.webp';
import demographicrento from '../../Assect/demographicrento.webp';
import structurerento from '../../Assect/structurerento.webp';
import { Footertab } from "../Footer/Footertab";
import { useEffect,useState } from 'react';
import CommonNav from '../Nav/CommonNav';
import ScrollUpButton from '../Home/Scrollup'
const Rento = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const scrollToAbout = () => {
        const aboutSection = document.getElementById('about');
        aboutSection.scrollIntoView({ behavior: 'smooth' });
    };
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    const handleCloseModal = () => {
        setSelectedImage(null);
    };
    return (
        <div>
            <ScrollUpButton/>
             <CommonNav scrollToAbout={scrollToAbout} />
             <br></br><br></br><br></br><br></br><br></br>
            <div className="maindiv">
                <p className="mainheading">RENTO</p>
                <p className="mainpara">Rent anything and everything; you don't need to own everything. This application helps<br></br> you to share your items for rent, and you can also borrow the items you need</p>
                <br></br>
                <img className="credentialling" src={rento} alt="rento" onClick={() => handleImageClick(rento)}/>
                <br></br><br></br><br></br><br></br>
                <div className="contents">
                    <h4 className="capitaltext">INTRODUCTION</h4>
                    <h2 className="headingtext">What is the project about?</h2>
                    <p className="paratext">The fundamental concept driving this project is to foster a sharing economy where individuals can easily access a wide range of items without the need for ownership. By facilitating the exchange of goods for rent, we aim to promote sustainability and resourcefulness within communities. Through this platform, users can seamlessly offer their possessions for rent to others who may have temporary needs, thereby maximizing the utility of items and minimizing unnecessary consumption.
                        <br></br>
                        
                        Our goal is to empower users to save money, reduce waste, and cultivate a culture of collaborative consumption where everyone benefits.</p>   </div>
                        
                <img className="pen" src={itimage} alt="itimage" onClick={() => handleImageClick(itimage)}/>
                <br></br><br></br><br></br><br></br>
                <div className="contents">
                    <h2 className="headingtext">Technical Info</h2>
                    <div className="tablecontent">
                        <div className="insidetable">
                            <p className="thtext">The problem:</p>
                            <p className="paratext">In our society, many people own too much stuff they don't often use, leading to waste and environmental harm. Traditional rental options are often inconvenient. We need a solution that makes it easy for people to share items they own and rent what they need, reducing waste and promoting a sense of community.</p>
                        </div>
                        <div className="insidetable">
                            <p className="thtext">The goal:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </p>
                            <p className="paratext">Our goal is to make it easy for people to share what they own and rent what they need, reducing waste and helping the environment while bringing communities closer together.</p>
                        </div>
                        <div className="insidetable">
                            <p className="thtext">The solution:</p>
                            <p className="paratext">We have design a user-friendly mobile application  where individuals can list items they own and are willing to rent out, and where others can easily search for and rent those items. The platform will include features such as secure payment processing, user reviews, and messaging capabilities to facilitate smooth transactions and build trust among users.</p>
                        </div>
                    </div>
                </div>
                <br></br><br></br><br></br>
                <div className="contents">
                    <h2 className="headingtext">Getting to know better my stakeholders and their needs</h2>
                    <p className="paratext">Renters need access to affordable, quality items with clear rental terms. Note: Transparency in pricing and item condition is crucial for renter satisfaction and trust.
                    </p>
                    
                    <div className="linepart">
                        <div className="vertical-linemeet"></div>
                        <p className="linepara">Users want a simple platform for renting and listing items, ensuring secure transactions and easy communication. Note: User experience and interface should be intuitive to encourage frequent usage.</p>
                    </div>
                </div>
                <br></br><br></br><br></br>
                <div className="contents">
                    <h4 className="capitaltext">DEFINE</h4>
                    <h2 className="headingtext">What exactly is the problem and who’s affected by it?</h2>
                    <p className="paratext">The problem is that many people spend too much money on buying things they don't really need, which strains their finances and leads to clutter. This affects everyone, from individuals struggling with debt to the economy burdened by excessive consumption.</p>
                    <br></br><br></br>
                    <h2 className="headingtext">so...who are we designing for?</h2>
                    <p className="paratext">We recognize two personas: the frequent traveler in need of many items, and the individual with excess belongings selling them online at a low price to clear space at home.</p>
                </div>
                <br></br>
                <img className="demographic" src={demographicrento} alt="demographicrento" onClick={() => handleImageClick(demographicrento)}/>
                <br></br><br></br><br></br><br></br><br></br><br></br>
                <div className="contents">
                    <h4 className="capitaltext">IDEATION</h4>
                    <h2 className="headingtext">Optimizing the User Flow</h2>
                    <p className="paratext"></p>
                     </div>
                     <br></br>
                <img className="structure" src={structurerento} alt="structurerento" onClick={() => handleImageClick(structurerento)}/>
                <br></br><br></br><br></br><br></br>
                <div className="contents">
                    <h4 className="capitaltext">DESIGN</h4>
                    <h2 className="headingtext">Final Design</h2>
                    <p className='paratext'><span className='spantext'>Our simple solution:</span> To design a user-friendly mobile application  where individuals can list items they own and are willing to rent out, and where others can easily search for and rent those items. The platform will include features such as secure payment processing, user reviews, and messaging capabilities to facilitate smooth transactions and build trust among users.</p>

                    <h3 className="subheading">Onboarding & Login Screens</h3>
                   </div>
                   <br></br>
                   <img className="final" src={mobile1} alt="mobile1" onClick={() => handleImageClick(mobile1)}/>
                   <br></br><br></br><br></br>
                   <img className="final" src={mobile2} alt="mobile2" onClick={() => handleImageClick(mobile2)}/>
                   <br></br><br></br><br></br><br></br>
                   <div  className="contents">
                   <h3 className="subheading">Home Screen</h3>
                   <p className='paratext'>Home screen featuring all categories listed in chips for easy filtering of desired items, along with a search option to directly find specific items for rent</p>
                   </div>
                 
                   <img className="final" src={mobile3} alt="mobile3" onClick={() => handleImageClick(mobile3)}/>
                   <br></br><br></br><br></br><br></br>
                   <div  className="contents">
                    <p className='paratext'>So let’s break this down one by one.</p>
                    <ul className='paratext'>
                        <li>The first card is highlighted; it will display items with the highest ratings and those that have been rented the most</li>
                        <li>Secondly, recommended items are posted based on users' searches and previously viewed items.</li>
                    </ul>
                   </div>
                  
                   <img className="final" src={mobile4} alt="mobile4" onClick={() => handleImageClick(mobile4)}/>
                   <br></br><br></br><br></br>
                   <div  className="contents">
                    <ul className='paratext'>
                        <li>Add Item is the screen where sellers can add their items for rent.</li>
                        <li>Chats are the communication tool between seller and receiver</li>
                        <li>Wishlist is the screen where all your saved items been listed.</li>
                    </ul>
                    <br></br><br></br><br></br>
                    <h3 className="subheading">Item Category and Description</h3>
                    <p className='paratext'>On the first screen, the item category is displayed with a highlighted image above, drawing primary attention. All items within the category are listed below.</p>
                    <ul className='paratext'>
                        <li>A search function is added at the top for easy navigation to the desired product</li>
                        <li>Below, filter and sort options are added, allowing users to customize their search based on their preferences.</li>
                        <li>Placed a wishlist icon on each item, allowing users to save items for purchase at a later time</li>
                    </ul>
                   </div>
                  
                   <img className="final" src={mobile5} alt="mobile5" onClick={() => handleImageClick(mobile5)}/>
                   <br></br><br></br><br></br>< br></br>
                   <div className='contents'>
                   <p className='paratext'>On the Second screen, its a item detail screen, where entire information of item is listed.</p>
                    <ul className='paratext'>
                        <li>Wishlist icon on top allowing users to save the item</li>
                        <li>The seller's details are provided along with a chat option, enabling users to communicate with the seller regarding the product before making a purchase, which helps users to gather more information about it.</li>
                        <li>Price details for an hour and a day have been added</li>
                        <li>You can check availability by clicking on the CTA added next to the price and see which dates are available</li>
                        <li>Finally, a 'Book Now' button is provided to proceed to the next step of purchasing</li>
                    </ul>
                    <br></br><br></br><br></br>
                    <h3 className="subheading">Chats</h3>
                    <p className='paratext'>This screen facilitates communication between the user and the seller, allowing the user to obtain additional details and clarification about the item</p>
                   </div>
                 
                   <img className="final" src={mobile6} alt="mobile6" onClick={() => handleImageClick(mobile6)}/>
                   <br></br><br></br><br></br><br></br>
                   <div className='contents'>
                    <h3 className="subheading">Profile Screen</h3>
                    <p className='paratext'>This screen includes all informative sections, such as chats, items you have added, leasing and rental history, payment support, and settings</p>
                   </div>
                  
                   <img className="final" src={mobile7} alt="mobile7" onClick={() => handleImageClick(mobile7)}/>
                   <br></br><br></br><br></br><br></br>
                   <div className='contents'>
                    <h3 className="subheading">Lease History and Rent Screen</h3>
                    <p className='paratext'>The <span className='spantext'>Lease History</span> screen lists all the items you have rented from the seller so far, with a 'Book Again' CTA to re-rent and a 'View Details' option to see further information about the selected item.</p>
                    <p className='paratext'>The <span className='spantext'>My Rents</span> screen shows the total number of items you have rented out for others to rent</p>
                   </div>
                   <br></br><br></br><br></br>
                   <img className="final" src={mobile8} alt="mobile8" onClick={() => handleImageClick(mobile8)}/>
                   <br></br><br></br><br></br>
                   <div className='contents'>
                    <h3 className="subheading">My Items</h3>
                    <p className='paratext'>This screen displays the items you have posted for rent. You can view the details you have added, click on 'Edit' to modify the information, and delete it whenever you wish. Once deleted, it will no longer be available for rent</p>
                   </div>
                   
                   <img className="final" src={mobile9} alt="mobile9" onClick={() => handleImageClick(mobile9)}/>
                   <br></br><br></br><br></br><br></br><br></br>
                   <div className="contents">
                    <h2 className="headingtext">Takeaways</h2>
                    <p className="subsubheading">So these were my learnings after working on this project.</p>
                    <ul className="paratext">
                        <li>It's an interesting application. I find this application truly promising, as it offers a convenient way for users to rent items instead of purchasing them, making it beneficial for all types of users.</li>
                        <li>I learned a lot from interviewing many individuals and understood that there are a lot of differences among people moving from one city to another. It was enjoyable to understand their perspectives and incorporate that knowledge into building the application</li>
                    </ul>
                </div>
                <br></br><br></br><br></br><br></br>
                <div className="contents">
                    <h2 className="headingtext">And… That’s a wrap!</h2>
                    <p className="paratext">I hope you guys found this case study useful and informative. Feel free to hit me up on any social media platform if you have any questions. I would be more than happy to have a chat with you.</p>
                </div>
                <br></br>
                <br></br><br></br>
            </div>
            {selectedImage && (
                <div className="modal">
                    <span className="close" onClick={handleCloseModal}>&times;</span>
                    <img src={selectedImage} alt="Selected Image" className="modal-image" />
                </div>
            )}
            <Footertab/>
        </div>
    );
};
export default Rento;