import './Credentialling.css';
import credentialling from '../../Assect/credentialling.webp';
import pen from '../../Assect/pen.webp';
import demographiccredential from '../../Assect/demographiccredential.webp';
import groupimage2 from '../../Assect/groupimage2.webp';
import structure from '../../Assect/structure.webp';
import final1 from '../../Assect/final1.webp';
import final2 from '../../Assect/final2.webp';
import final3 from '../../Assect/final3.webp';
import final4 from '../../Assect/final4.webp';
import final5 from '../../Assect/final5.webp';
import final6 from '../../Assect/final6.webp';
import { Footertab } from "../Footer/Footertab";
import { useEffect,useState } from 'react';
import CommonNav from '../Nav/CommonNav';
import ScrollUpButton from '../Home/Scrollup'
const Credentialling = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const scrollToAbout = () => {
        const aboutSection = document.getElementById('about');
        aboutSection.scrollIntoView({ behavior: 'smooth' });
    };
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    const handleCloseModal = () => {
        setSelectedImage(null);
    };
    return (
        <div>
            <ScrollUpButton/>
            <CommonNav scrollToAbout={scrollToAbout} />
            <br></br><br></br><br></br><br></br><br></br>
            <div className="maindiv">
                <p className="mainheading">Credentialling</p>
                <p className="mainpara">The goal of this initiative is to expedite dental professionals' lengthy credentialing procedure.</p>
                <br></br>
                <img className="credentialling" src={credentialling} alt="credentialling" onClick={() => handleImageClick(credentialling)}/>
                <br></br><br></br><br></br><br></br><br></br>
                <div className="contents">
                    <h4 className="capitaltext">INTRODUCTION</h4>
                    <h2 className="headingtext">What is the project about?</h2>
                    <p className="paratext">This project aims to streamline the lengthy credentialing process for dental providers, which typically occurs every 2 to 3 years, by transitioning it into a simple and digital procedure. The goal is to achieve maximum efficiency, ensuring that providers can maintain active status with all associated payers. This, in turn, allows for better service to patients.
                        <br></br>
                        <br></br>
                        To grasp the importance of credentialing, it's essential to recognize that it provides assurance to insurance companies and patients that a dental provider is in good standing and practices within the necessary standards.
                        <br></br>
                        <br></br>
                        As a dentist, you have the option to become certified with any insurance carrier, but regardless of your choice, individual credentials are required for each network. Group credentialing is not an option.</p>
                </div>
               
                <img className="pen" src={pen} alt="pen" onClick={() => handleImageClick(pen)}/>
                <br></br><br></br><br></br><br></br><br></br><br></br><br></br>
                <div className="contents">
                    <h2 className="headingtext">Technical Info</h2>
                    <div className="tablecontent">
                        <div className="insidetable">
                            <p className="thtext">The problem:</p>
                            <p className="paratext">Credentialing is currently done only through physical paperwork, which is crucial and time-consuming to compile accurately. Errors in submission prolong the process, preventing providers from working with the insurance company and impacting revenue.</p>
                        </div>
                        <div className="insidetable">
                            <p className="thtext">The goal:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </p>
                            <p className="paratext">Easier way to cover all aspects of the credentialing process, from assembling comprehensive documentation to tracking the progress of an application and delivering any follow-up documents required by an insurance company allowing for convenient review of progress at your fingertips.</p>
                        </div>
                        <div className="insidetable">
                            <p className="thtext">The solution:</p>
                            <p className="paratext">An additional module on the provider's current site, where they have already updated the payer information they are affiliated with, will enable them to digitally digitise each stage of the credentialing process for every payer with which they are involved.</p>
                        </div>
                    </div>
                </div>
                <br></br><br></br><br></br><br></br>
                <div className="contents">
                    <h2 className="headingtext">Getting to know better my stakeholders and their needs</h2>
                    <p className="paratext">We posed open-ended questions at our Teams meeting with the senior product owner of the healthcare firm, such as: How is the present circumstance unfolding? How, in your opinion, does digitization facilitate form submission? etc.,
                        <br></br><br></br>
                        Then, in order to understand the current credentialing turnaround time, we held numerous meetings with product owners. We also attempted to learn about the technology used to compile all of the submitted proofs and details into a single package for submission, as well as how the medical department handles digitally created applications.
                    </p>
                  
                    <div className="linepart">
                        <div className="vertical-linecred"></div>
                        <p className="linepara">“The issue here is turnaround time; you have to file for each payer individually, one at a time, and it takes too long to gain approval if it is denied.”</p>
                    </div>
                </div>
                <br></br><br></br><br></br>
                <div className="contents">
                    <h4 className="capitaltext">DEFINE</h4>
                    <h2 className="headingtext">What exactly is the problem and who’s affected by it?</h2>
                    <p className="paratext">The exact issue is having to apply individually for each payer with whom you are affiliated. For instance, if he is associated with twelve payers, he must provide them with twelve sets of copies of all proofs. The issue arises when he tries to trace down the applications that are submitted for approval.
                        <br></br><br></br>
                        The primary issue arises from failing to recall the expiration date of credentialling of each payer.It can be challenging to remember deadlines, which makes it more difficult to accept patients from a certain payer if the provider fails to complete credentialing by the deadline</p>
                    <br></br>
                    <p className="paratext">Problems to address : <span className="spantext">Doing a credentialing for many payers at a time, Recall the Expiry date & alert them well ahead</span></p>
                    <br></br><br></br><br></br>
                    <h2 className="headingtext">so...who are we designing for?</h2>
                    <p className="paratext">There are actually two personas that we recognised. The individual provider and the additional provider administrator. The administrative personnel that handles credentialing for all clinicians in a hospital setting is known as provider admin. We determined that the provider administrator had the largest participation in doing the credentialing.</p>
                </div>
               
                <img className="demographic" src={demographiccredential} alt="demographiccredential" onClick={() => handleImageClick(demographiccredential)}/>
                <br></br><br></br><br></br><br></br>
                <div className="contents">
                    <h2 className="headingtext">Understanding what’s the general journey of user</h2>
                    <p className="paratext">The list of credentials that provider admins must complete for the providers they are dealing with is displayed on the dashboard, which is where the General journey starts. This entails splitting the certification process into two primary components. Filling out a fresh application for credentials and renewing them for licences that are about to expire
                        <br></br><br></br>
                        Seeing a form filled out to completion gave me the idea to see how the stages might be designed to be easier for users to follow and how they could group their expiring credentials and complete the assignment.
                    </p>
                </div>
               
                <img className="groupimage" src={groupimage2} alt="groupimage" onClick={() => handleImageClick(groupimage2)}/>
                <br></br><br></br><br></br><br></br>
                <div className="contents">
                    <h4 className="capitaltext">IDEATION</h4>
                    <h2 className="headingtext">Brainstormed the possible UX for the new feature</h2>
                    <p className="paratext">We recognise primarily that we must demonstrate the application's progress since there is a possibility that the provider admin may abandon the application in the middle and return to it later. Furthermore, we must ensure that the user feels as though they are filling out a form in its original language while simultaneously ensuring that it has been digitalized.</p>
                    <br></br><br></br>
                    <h2 className="headingtext">Optimizing the site structure</h2>
                    <p className="paratext">We streamlined the procedure and form-filling technique by combining related proofs and detains into a single step to ensure that they complete everything together. We also removed a few steps that were repetitive in the form-filling process but could have been easily filed in digitalization if they had been included in the master data.</p>
                </div>
              
                <img className="structure" src={structure} alt="structure" onClick={() => handleImageClick(structure)}/>
                <br></br><br></br><br></br><br></br><br></br>
                <div className="contents">
                    <h4 className="capitaltext">DESIGN</h4>
                    <h2 className="headingtext">Final Design</h2>
                    <p className='paratext'>After many iterations, we finally came up with a method to group multiple credentialing applications into one and display the application's progress. Although the applications will be sent separately, users only need to fill out the form once, and we create separate packages for each payer.</p>
                    <br></br>
                    <h3 className="subheading">Bulk Application - Select Multiple Credentialing Application together</h3>
                    <p className="paratext">Breaking away from the standard process, we gathered the required fields and organised them according to the expectations of all payers, rather than spending the effort to create an application for every payer</p>
                </div>
                <br></br>
                <img className="final" src={final1} alt="final" onClick={() => handleImageClick(final1)}/>
                <br></br><br></br><br></br><br></br>
                <div className="contents">
                    <p className='paratext'>So let’s break this down one by one.</p>
                    <ul className='paratext'>
                        <li>
                            We had categorized the application to be for credentialed into three main category:
                            <ul className='liststyle'>
                                <li>- Out of Network :This includes the New Payer’s enrollment and Credentialing Application that has been inactive for more than 60 days.</li>
                                <li>- Due for Cred: This category applies to any application that has expired but is still less than 60 days old.</li>
                                <li>- Application in Progress : The credentialling or recredentialling applications which are already submitted for approval.By categorizing , we achieved a way to enable users to multi select many applications together to do credentialling.</li>
                            </ul>
                        </li>
                        <li>By categorizing , we achieved a way to enable users to multi select many applications together to do credentialling.</li>                            </ul>

                </div>
                <br></br><br></br><br></br><br></br>
                <div className='contents'>
                    <h2 className="headingtext">Application Progress Screen</h2>
                    <p className='paratext'>As we understood, the application will be left off in a stage and might be continued later. To make it look smaller, we decided to go ahead with an application progress screen.</p>
                </div>
                <br></br>
                <img className="final" src={final2} alt="final" onClick={() => handleImageClick(final2)}/>
                <br></br><br></br><br></br><br></br>
                <div className='contents'>
                    <p className='paratext'>So let’s break this down one by one.</p>
                    <ul className='paratext'>
                        <li>To make sure that customers feel more at ease when filling out the lengthy form, we have broken it into six categories. This way, they won't be intimidated by the large number of pages.</li>
                        <li>Additionally, we display on the left side the application insights that they are completing, particularly with regard to their address and the type of credentialing they are completing.</li>
                        <li>We assembled the Possible payers and plan type on the left side to create a credentialing packet of documentation.</li>
                    </ul>
                    <br></br><br></br>
                    <h2 className="headingtext">3 Step form Filling</h2>
                    <p className='paratext'>We categorised all the relevant data into three primary groupings, eliminated any fields that weren't necessary and also with group names, we are hinting at what's expected in that step so they can have all the necessary paperwork or information ready for each step.</p>
                </div>
                <br></br>
                <img className="final" src={final3} alt="final" onClick={() => handleImageClick(final3)}/>
                <br></br><br></br><br></br><br></br>
                <div className='contents'>
                    <p className='paratext'>In the three steps 3 form filling</p>
                    <ul className='paratext'>
                        <li><span className='spantext'>First Step:</span> This entails providing comprehensive educational and specialty details, along with demographic information of locations served by the providers.
                        </li>
                        <li><span className='spantext'>Second Step:</span> This involves uploading all necessary documents to support the information filled out in the first step.
                        </li>
                        <li><span className='spantext'>Third Step:</span> This concerns establishing the legal entity's name, tax classification, and the locations where services are provided. It includes operational hours and whether there are any differences in billing and service locations</li>
                    </ul>
                    <br></br><br></br>
                    <h2 className="headingtext">Answer Disclosures & Self Assessment</h2>
                    <p className='paratext'>We separated disclosures and self-assessment to emphasize the importance of understanding each criterion correctly by the provider and responding accordingly. This ensures that they accurately identify the services, specialties, or products they use to serve people.</p>
                </div>
                <br></br>
                <img className="final" src={final4} alt="final" onClick={() => handleImageClick(final4)}/>
                <br></br><br></br><br></br><br></br>
                <div className='contents'>
                    <p className='paratext'>It’s a two step Process</p>
                    <ul className='paratext'>
                        <li>The majority of questions are binary, requiring a yes or no response, while some include an explanation textbox to provide further context for the selected answer.</li>
                        <li>We've reorganized the questions into categories, allowing respondents to navigate through pages to address disclosures by various topics such as Staff Health, Liability, etc.</li>
                        <li>Each location must independently complete the Self-Assessment questions. We've implemented a feature where respondents can answer for one location, and this information will be propagated to all locations. However, they are required to verify the accuracy before proceeding.</li>
                    </ul>
                    <br></br>
                    <h2 className="headingtext">Review Application</h2>
                    <p className='paratext'>This step is purposefully organized to gather all provided information and uploaded documents into a centralized space, facilitating efficient and thorough review.</p>
                </div>
                <br></br>
                <img className="final" src={final5} alt="final" onClick={() => handleImageClick(final5)}/>
                <br></br><br></br><br></br><br></br>
                <div className='contents'>
                    <ul className='paratext'>
                        <li>Within this step, alongside the preview feature, we've included an option to highlight any errors in the fields on the left side. This allows users to easily identify and correct any mistakes from the corresponding menu.</li>
                        <li>Additionally, users have the option to download a sample package containing all the data they've provided, showcasing how it will appear in the final version.</li>
                        <li>If any corrections are required, instead of navigating through each step, users can simply click on the "edit" option next to each column, directing them to the relevant section for editing.</li>
                    </ul>
                    <br></br><br></br>
                    <h2 className="headingtext">Provider Signature Screen</h2>
                    <p className='paratext'>Nevertheless, the provider admin completes the form on provider's behalf. However, in order to prepare the package for mailing for approval, the provider must confirm and sign it.</p>
                </div>
                <br></br>
                <img className="final" src={final6} alt="final" onClick={() => handleImageClick(final6)}/>
                <br></br><br></br><br></br><br></br>
                <div className='contents'>
                    <p className='paratext'>So let’s break this down one by one.</p>
                    <ul className='paratext'>
                        <li>In order to make it easier for providers to evaluate and sign the applications, we provided them the chance to preview each one as it would be created.</li>
                        <li>An option to sign the application using a mouse has been provided, making it easier and more permissible.</li>
                        <li>Additionally, by highlighting those, we provide providers the ability to see where signatures will be used in the applications</li>
                    </ul>
                    <br></br><br></br><br></br>
                    <h2 className="headingtext">Takeaways</h2>
                    <p className='paratext'>By closely observing how it now functions in physical form, it was possible to understand how to digitise lengthy forms into gratifying, faster ways to fill them out. This also allowed them to benefit from technological advancements that could execute many tasks simultaneously and prevent redundant labour. The greatest thing and greatest lesson I have ever learned in my career is that we were able to overcome the issue of recreating the same natural sense of form filling while still making the process simpler.</p>
                    <br></br><br></br>
                    <h2 className="headingtext">And… That’s a wrap!</h2>
                    <p className='paratext'>I hope you guys found this case study useful and informative. Feel free to hit me up on any social media platform if you have any questions. I would be more than happy to have a chat with you.</p>
                </div>
                <br></br><br></br><br></br>
            </div>
            {selectedImage && (
                <div className="modal">
                    <span className="close" onClick={handleCloseModal}>&times;</span>
                    <img src={selectedImage} alt="Selected Image" className="modal-image" />
                </div>
            )}
            <Footertab />
        </div>
    );
};
export default Credentialling;