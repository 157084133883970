import React from 'react';
import { Link } from 'react-router-dom'; 
import projectimg1 from '../../../src/Assect/bestproject1.webp';
import projectimg2 from '../../../src/Assect/bestproject2.webp';
import projectimg3 from '../../../src/Assect/bestproject3.webp';
import projectimg4 from '../../../src/Assect/bestproject14.webp';
import './bestproject.css'

const Bestproject = () => {
    return (
        <div>
            <div className="projects-container">
                <div className="p-font">
                    <p>Explore my best projects <hr className="pline" />  </p>
                </div>
                <br />
                <div className="row">
                    <div className="column">
                        <div className="card">
                            <Link to="/meetmuse">
                                <img className="Bestproject1" src={projectimg1} alt="Image 1" />
                            </Link>
                            <h3>MEETMUSE AI</h3>
                            <p>Effortlessly transform meeting transcripts into concise summaries with our meeting summary app. Simply upload the transcript and receive key insights in seconds</p>
                        </div>
                    </div>
                    <div className="column">
                        <div className="card">
                            <Link to="/safedive">
                                <img className="Bestproject1" src={projectimg2} alt="Image 2" />
                            </Link>
                            <h3>SAFE DIVE</h3>
                            <p>The Safe Dive App enables scuba divers to communicate, navigate to the boat, send emergency signals, and monitor oxygen levels and heart rate, ensuring safety with simplicity.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="column">
                        <div className="card">
                            <Link to="/rento">
                                <img className="Bestproject1" src={projectimg3} alt="Image 3" />
                            </Link>
                            <h3>RENTO</h3>
                            <p>Rent anything and everything; you don't need to own everything. This application helps you to share your items for rent, and you can also borrow the items you need</p>
                        </div>
                    </div>
                    <div className="column">
                        <div className="card">
                            <Link to="/credentialling">
                                <img className="Bestproject1" src={projectimg4} alt="Image 4" />
                            </Link>
                            <h3>CREDENTIALING - DENTAL PROVIDERS</h3>
                            <p>This initiative aims to streamline the extensive credentialing process for dental professionals, expediting their journey into practice.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="button-containersee">
            <Link to="/workpage" className="seemore-button">See More Work</Link>
            <br></br> <br></br>
                <p className='seesub'>As you know, most of my work I can’t disclose publicly,<br></br>
                you know what I mean (under NDA)</p>
            </div>
            <br></br><br></br> <br></br><br></br>
        </div>
    );
}

export  {Bestproject};
