import React from 'react';
import './intraction.css';
// import videoSource from '../../Assect/pet id video_1.gif';
// import videoSource1 from '../../Assect/fielda video.gif';

const Interactions = () => {
    // Replace 'https://media.giphy.com/media/HBOMax-tom-and-jerry-hbomax-n-EZICHGrSD5QEFCxMiC/giphy.gif' with the direct URL of your GIF
    const gifUrl = 'http://servell.net//1.pet%20id%20video_1.gif';
    const gifUrl2 = 'http://servell.net/1.fielda%20video.gif';
    return (
        <div id="Interactions">
            {/* <div className="pd-font">
                <p>Interactions</p>
                <hr className="tline" />
            </div>
            <br /> */}
            <div>
                <figure className="Iimage">
                    {/* <img src={gifUrl} alt="GIF Image" />
                    <img src={gifUrl2} alt="Image 2" /> */}
                    {/* <img src={videoSource1} alt="GIF Image" />
                    <img src={videoSource} alt="Image 2" /> */}
                </figure>
            </div>
        </div>
    );
}

export { Interactions };
    