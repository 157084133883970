import React, { useState, useEffect } from 'react';
import '../Home/Visuals';
import CommonNav from '../Nav/CommonNav';
import './workpage.css'
import { Footertab } from '../Footer/Footertab';
import { Interactions } from './Interaction'
import {Designsystem} from './Designsystem'
import img1 from '../../Assect/w1.png';
import img2 from '../../Assect/w2.png';
import img3 from '../../Assect/w3.png';
import img4 from '../../Assect/w4.png';
import img5 from '../../Assect/w5.png';
import img6 from '../../Assect/w6.png';
import img7 from '../../Assect/w7.png';
import img8 from '../../Assect/w8.png';
import img9 from '../../Assect/w9.png';
import img10 from '../../Assect/w10.png';
import img11 from '../../Assect/w11.png';
import img12 from '../../Assect/w12.png';
import img13 from '../../Assect/w13.png';
import img14 from '../../Assect/w14.png';
import img15 from '../../Assect/w15.png';
import img16 from '../../Assect/w16.png';


import img01 from '../../Assect/bw1.webp';
import img02 from '../../Assect/bw2.webp';
import img03 from '../../Assect/bw3.webp';
import img04 from '../../Assect/bw4.webp';
import img05 from '../../Assect/bw5.webp';
import img06 from '../../Assect/bw6.webp';
import img07 from '../../Assect/bw7.webp';
import img08 from '../../Assect/bw8.webp';
import img09 from '../../Assect/bw9.webp';
import img010 from '../../Assect/bw10.webp';
import img011 from '../../Assect/bw11.webp';
import img012 from '../../Assect/bw12.webp';
import img013 from '../../Assect/bw13.webp';
import img014 from '../../Assect/bw14.webp';
import img015 from '../../Assect/bw15.webp';
import img016 from '../../Assect/bw16.webp';


// Best Project
import '../Home/Bestpoject';
import projectimg1 from '../../../src/Assect/bestproject1.webp';
import projectimg2 from '../../../src/Assect/bestproject2.webp';
import projectimg3 from '../../../src/Assect/bestproject3.webp';
import projectimg4 from '../../../src/Assect/bestproject14.webp';
import { Link } from 'react-router-dom'; 

import ScrollUpButton from '../Home/Scrollup'
const Workpage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    const handleCloseModal = () => {
        setSelectedImage(null);
    };

    const scrollToInteractions = () => {
        const interactionsSection = document.getElementById('Interactions');
        if (interactionsSection) {
            interactionsSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToDesignSystem = () => {
        const designSystemSection = document.getElementById('DesignSystem');
        if (designSystemSection) {
            designSystemSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToCaseStudy = () => {
        const CaseStudy = document.getElementById('CaseStudy');
        if (CaseStudy) {
            CaseStudy.scrollIntoView({ behavior: 'smooth' });
        }
       
    };
    const handleContextMenu = (event) => {
        event.preventDefault(); // Prevent default right-click behavior
    };
    return (
        <div onContextMenu={handleContextMenu}>
        <ScrollUpButton/>
            <CommonNav />
            <div className="content"  >
                <h1 className="Sub-header">Handpicked Works<br></br> Done over the years </h1>
                {/* <h1 className="Sub-header">Done over the years</h1> */}
                <div className="button-contain">
                    <button className="button">Visual Design</button>
                    {/* <button className="button" onClick={scrollToInteractions}>Interactions</button> */}
                    <button className="buttonn" onClick={scrollToDesignSystem}>Design System</button>
                    <button className="button" onClick={scrollToCaseStudy}>Case Study</button>
                </div>
            </div>
            <div className='work-container'>
                <div className="w-font"> <p>Visual Design<hr className="wline" />  </p> </div><br></br>
                <div className="img-row">
                    <div >
                        <img src={img1} alt="Image 1" onClick={() => handleImageClick(img01)}/>
                        <img src={img2} alt="Image 2" onClick={() => handleImageClick(img02)}/>
                        <img src={img3} alt="Image 3" onClick={() => handleImageClick(img03)}/>
                        <img src={img4} alt="Image 4" onClick={() => handleImageClick(img04)}/>
                    </div><div>
                        <img src={img5} alt="Image 5" onClick={() => handleImageClick(img05)}/>
                        <img src={img6} alt="Image 6" onClick={() => handleImageClick(img06)}/>

                        <img src={img7} alt="Image 1" onClick={() => handleImageClick(img07)}/>
                        <img src={img8} alt="Image 2" onClick={() => handleImageClick(img08)}/>
                    </div>
                    <div>  <img src={img9} alt="Image 3" onClick={() => handleImageClick(img09)}/>
                        <img src={img10} alt="Image 4" onClick={() => handleImageClick(img010)}/>
                        <img src={img11} alt="Image 5" onClick={() => handleImageClick(img011)}/>
                        <img src={img12} alt="Image 6" onClick={() => handleImageClick(img012)}/></div>
                    <div>
                        <img src={img13} alt="Image 1" onClick={() => handleImageClick(img013)}/>
                        <img src={img14} alt="Image 2" onClick={() => handleImageClick(img014)}/>
                        <img src={img15} alt="Image 3" onClick={() => handleImageClick(img015)}/>
                        <img src={img16} alt="Image 4" onClick={() => handleImageClick(img016)}/>
                    </div>
                   
                   
                </div>
                 
            {/* Modal for displaying selected image */}
            {selectedImage && (
                <div className="modal">
                    <span className="close" onClick={handleCloseModal}>&times;</span>
                    <img src={selectedImage} alt="Selected Image" className="modal-image" />
                </div>
            )}
            </div>
            <Interactions/>
            {/* Best Project */}
            <div id="CaseStudy">
                <div className="work-container">
                    <div className="w-font"> <p>Case Study <hr className="wline" />  </p> </div><br></br>
                    {/* First Row */}
                    <div className="row">
                    <div className="column">
                        <div className="card">
                            <Link to="/meetmuse">
                                <img className="Bestproject1" src={projectimg1} alt="Image 1" />
                            </Link>
                            <h3>MEETMUSE AI</h3>
                            <p>Effortlessly transform meeting transcripts into concise summaries with our meeting summary app. Simply upload the transcript and receive key insights in seconds</p>
                        </div>
                    </div>
                    <div className="column">
                        <div className="card">
                            <Link to="/safedive">
                                <img className="Bestproject1" src={projectimg2} alt="Image 2" />
                            </Link>
                            <h3>SAFE DIVE</h3>
                            <p>The Safe Dive App enables scuba divers to communicate, navigate to the boat, send emergency signals, and monitor oxygen levels and heart rate, ensuring safety with simplicity.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="column">
                        <div className="card">
                            <Link to="/rento">
                                <img className="Bestproject1" src={projectimg3} alt="Image 3" />
                            </Link>
                            <h3>RENTO</h3>
                            <p>Rent anything and everything; you don't need to own everything. This application helps you to share your items for rent, and you can also borrow the items you need</p>
                        </div>
                    </div>
                    <div className="column">
                        <div className="card">
                            <Link to="/credentialling">
                                <img className="Bestproject1" src={projectimg4} alt="Image 4" />
                            </Link>
                            <h3>CREDENTIALING - DENTAL PROVIDERS</h3>
                            <p>This initiative aims to streamline the extensive credentialing process for dental professionals, expediting their journey into practice.</p>
                        </div>
                    </div>
                </div>
            </div>
                
                <br></br><br></br> <br></br><br></br>

              
            </div>
            <Designsystem scrollToDesignSystem={scrollToDesignSystem} />
           <Footertab />
        </div>
    );
}

export default Workpage; // Export default
